html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.ql-editor {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: rgb(26, 32, 44);
}

.ql-toolbar.ql-snow {
  border-color: rgb(226, 232, 240);
}

.ql-container.ql-snow {
  border-color: rgb(226, 232, 240);
}
